import React, { Component } from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"

import logoWhite from "../images/logo-white.svg"
import logo from "../images/logo.svg"

import SellMore from "../images/icons/sell-more.svg"
import KeepCustomers from "../images/icons/keep-customers.svg"
import DistributeContent from "../images/icons/distribute-content.svg"
import SellDirectly from "../images/icons/sell-directly.svg"

class Navbar extends Component {
  constructor (props) {
    super(props);

    if (typeof window !== 'undefined') {
      window.onscroll = function () {
        if (window.pageYOffset > 82) {
          document.getElementById('navbar').classList.add('is-scrolled', 'is-fixed-top', 'is-color');
        } else {
          document.getElementById('navbar').classList.remove('is-scrolled', 'is-fixed-top');
          
          if (!document.getElementsByClassName('post__title').length) {
            document.getElementById('navbar').classList.remove('is-color');
          }
        }
      }
    }
  }

  mobileMenu () {
    const navbarBurger = document.getElementById('navbarBurger');
    const navbarMenu = document.getElementById('navbarBasic');

    navbarBurger.classList.toggle('is-active');
    navbarMenu.classList.toggle('is-active');
  }

  isBlog () {
    if (document.getElementsByClassName('post__title').length) {
      document.getElementById('navbar').classList.add('is-color');
      document.getElementsByTagName('html')[0].classList.add('is-blog');
    } else {
      document.getElementById('navbar').classList.remove('is-color');
      document.getElementsByTagName('html')[0].classList.remove('is-blog');
    }
  }

  isWebinars () {
    if (document.getElementsByClassName('heroes--webinar').length) {
      document.getElementsByTagName('html')[0].classList.add('is-webinars');
    } else {
      document.getElementsByTagName('html')[0].classList.remove('is-webinars');
    }
  }

  hasTopBar () {
    if (document.getElementsByClassName('is-topbar').length) {
      document.getElementsByTagName('html')[0].classList.add('has-topbar');
    } else {
      document.getElementsByTagName('html')[0].classList.remove('has-topbar');
    }
  }

  componentDidMount () {
    this.isBlog();
    this.isWebinars();
    this.hasTopBar();
  }

  render () {
    return (
      <>
        <nav id="navbar" className="navbar is-transparent" role="navigation" aria-label="main navigation">
          <div className="container">
            <div className="navbar-brand">
              <Link to="/" className="navbar-item navbar-logo">
                <img src={logoWhite} alt="ExpertSender" width="226" height="30" className="navbar-logo__white" />
                <img src={logo} alt="ExpertSender" width="226" height="30" className="navbar-logo__color" />
              </Link>

              <Link to="/blog" className="navbar-item navbar-item--blog">
                <span className="navbar__subname">
                  <FormattedMessage id="navbar.blog" />
                </span>
              </Link>

              <div className="navbar-item navbar-item--webinars">
                <span className="navbar__subname">
                  <FormattedMessage id="navbar.webinars" />
                </span>
              </div>

              <div role="button" tabindex="0" id="navbarBurger" className="navbar-burger burger" aria-label="menu" aria-expanded="false" onClick={this.mobileMenu} onKeyDown={this.mobileMenu}>
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </div>
            </div>

            <div id="navbarBasic" className="navbar-menu">
              <div className="navbar-end">
                <div class="navbar-item has-dropdown is-hoverable">
                  <div class="navbar-link is-arrowless">
                    <FormattedMessage id="navbar.i_want_to" />
                  </div>
                  <div class="navbar-dropdown navbar-dropdown--product is-boxed">
                    <Link to="/i-want-to/sell-more" class="navbar-item" activeClassName="navbar-item">
                      <div className="columns">
                        <div className="column is-narrow is-hidden-mobile">
                          <SellMore className="navbar-dropdown__ico" />
                        </div>
                        <div className="column">
                          <div className="navbar-dropdown__name">
                            <FormattedMessage id="navbar.sell_more" />
                          </div>
                          <div className="navbar-dropdown__desc is-hidden-mobile">
                            <FormattedMessage id="navbar.sell_more_txt" />
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="/i-want-to/keep-customers-coming-back" class="navbar-item" activeClassName="navbar-item">
                      <div className="columns">
                        <div className="column is-narrow is-hidden-mobile">
                          <KeepCustomers className="navbar-dropdown__ico" />
                        </div>
                        <div className="column">
                          <div className="navbar-dropdown__name">
                            <FormattedMessage id="navbar.keep_customers" />
                          </div>
                          <div className="navbar-dropdown__desc is-hidden-mobile">
                            <FormattedMessage id="navbar.keep_customers_txt" />
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="/i-want-to/distribute-my-content" class="navbar-item" activeClassName="navbar-item">
                      <div className="columns">
                        <div className="column is-narrow is-hidden-mobile">
                          <DistributeContent className="navbar-dropdown__ico" />
                        </div>
                        <div className="column">
                          <div className="navbar-dropdown__name">
                            <FormattedMessage id="navbar.distribute_content" />
                          </div>
                          <div className="navbar-dropdown__desc is-hidden-mobile">
                            <FormattedMessage id="navbar.distribute_content_txt" />
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="/i-want-to/sell-directly-to-customers-d2c" class="navbar-item" activeClassName="navbar-item">
                      <div className="columns">
                        <div className="column is-narrow is-hidden-mobile">
                          <SellDirectly className="navbar-dropdown__ico" />
                        </div>
                        <div className="column">
                          <div className="navbar-dropdown__name">
                            <FormattedMessage id="navbar.sell_directly" />
                          </div>
                          <div className="navbar-dropdown__desc is-hidden-mobile">
                            <FormattedMessage id="navbar.sell_directly_txt" />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div class="navbar-item has-dropdown is-hoverable">
                  <div class="navbar-link is-arrowless">
                    <FormattedMessage id="navbar.success_stories" />
                  </div>
                  <div class="navbar-dropdown is-boxed">
                    <Link to="/success-stories/ecommerce" className="navbar-item" activeClassName="navbar-item">
                      <FormattedMessage id="navbar.ecommerce" />
                    </Link>
                    <Link to="/success-stories/travel-and-hospitality" className="navbar-item" activeClassName="navbar-item">
                      <FormattedMessage id="navbar.travel_and_hospitality" />
                    </Link>
                    <Link to="/success-stories/finance-and-banking" className="navbar-item" activeClassName="navbar-item">
                      <FormattedMessage id="navbar.finance_and_banking" />
                    </Link>
                    <Link to="/success-stories/automotive" className="navbar-item" activeClassName="navbar-item">
                      <FormattedMessage id="navbar.automotive" />
                    </Link>
                    <Link to="/success-stories/media-and-publishing" className="navbar-item" activeClassName="navbar-item">
                      <FormattedMessage id="navbar.media_and_publishing" />
                    </Link>
                  </div>
                </div>

                <div class="navbar-item has-dropdown is-hoverable">
                  <div class="navbar-link is-arrowless">
                    <FormattedMessage id="navbar.why_expertsender" />
                  </div>
                  <div class="navbar-dropdown is-boxed">
                    <Link to="/why-expertsender/experience" className="navbar-item" activeClassName="navbar-item">
                      <FormattedMessage id="navbar.experience" />
                    </Link>
                    <Link to="/why-expertsender/values" className="navbar-item" activeClassName="navbar-item">
                      <FormattedMessage id="navbar.values" />
                    </Link>
                    <Link to="/why-expertsender/team" className="navbar-item" activeClassName="navbar-item">
                      <FormattedMessage id="navbar.team" />
                    </Link>
                    <Link to="/why-expertsender/security" className="navbar-item" activeClassName="navbar-item">
                      <FormattedMessage id="navbar.security" />
                    </Link>
                    <Link to="/why-expertsender/gdpr-and-iso-27001" className="navbar-item" activeClassName="navbar-item">
                      <FormattedMessage id="navbar.gdpr_iso" />
                    </Link>
                  </div>
                </div>

                <div class="navbar-item has-dropdown is-hoverable">
                  <div class="navbar-link is-arrowless">
                    <FormattedMessage id="navbar.contact" />
                  </div>
                  <div class="navbar-dropdown is-boxed">
                    <a class="navbar-item" href="/demo/">
                      <FormattedMessage id="navbar.request_demo" />
                    </a>
                    <a class="navbar-item" href="/contact-us/">
                      <FormattedMessage id="navbar.contact_us" />
                    </a>
                  </div>
                </div>

                <div class="navbar-item has-dropdown is-hoverable">
                  <div class="navbar-link navbar-link--lang is-arrowless">
                    <FormattedMessage id="navbar.lang" />
                  </div>
                  <div class="navbar-dropdown is-boxed">
                    <a class="navbar-item" href="https://expertsender.com">
                      English
                    </a>
                    <a class="navbar-item" href="https://expertsender.pl">
                      Polski
                    </a>
                    <a class="navbar-item" href="https://expertsender.ru">
                      Русский
                    </a>
                    <a class="navbar-item" href="https://expertsender.fr">
                      Français
                    </a>
                    <a class="navbar-item" href="https://expertsender.com.br">
                      Português
                    </a>
                    <a class="navbar-item" href="https://expertsender.cn">
                      简体中文
                    </a>
                  </div>
                </div>

                <div className="navbar-item">
                  <div className="buttons">
                    <a href="/demo/" className="button is-white navbar__cta">
                      <FormattedMessage id="navbar.request_demo" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default Navbar
